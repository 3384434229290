/* 基础样式 - 保留 */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 布局样式 */
.layout {
  min-height: 100vh;
}

.ant-layout-content {
  background: #f0f2f5;
}

/* Header 样式 */
.ant-layout-header {
  background: #001529 !important;
  padding: 0 24px;
  line-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-layout-header .ant-menu {
  flex: 0 0 auto;
  background: #001529;
}

.ant-layout-header .ant-space {
  flex: 1;
}

.ant-layout-header .ant-typography {
  color: #fff !important;
  margin-bottom: 0 !important;
}

.ant-layout-header .ant-btn-link {
  color: #fff !important;
}

.ant-layout-header .ant-btn-link:hover {
  color: #40a9ff !important;
}

/* 组件样式 */
.ant-card {
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 24px;
}

.ant-btn {
  border-radius: 4px;
}

.ant-btn-primary {
  background: #1890ff;
  border-color: #1890ff;
}

.ant-btn-primary:hover {
  background: #40a9ff;
  border-color: #40a9ff;
}

/* 表格样式 */
.ant-table {
  margin-top: 24px;
}

.ant-table-thead > tr > th {
  background: #f8f9fa;
}

/* 上传组件样式 */
.ant-upload-list {
  margin: 16px 0;
}

/* 菜单样式 */
.ant-menu-dark .ant-menu-item-selected {
  background-color: #1890ff;
}